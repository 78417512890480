/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import ManagePage from "./modules/Manage/ManagePage";
import ManagePage2 from "./modules/Manage/ManagePage2";
import VerifyEmail from "./pages/VerifyEmail";
import Success from "./modules/Manage/pages/Success";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  );

  return (
    <Switch>
      {!isAuthorized ? (
        <Route
          path="/auth/login/:auth_token?/:refresh_token?"
          component={AuthPage}
        />
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      <Route exact path="/manage/pref/success">
        <Success />
      </Route>
      <Route path="/manage">
        <ManagePage2 />
      </Route>
      {/* <Route exact path="/manage/:prefCode">
        <ManagePage />
      </Route> */}

      <Route path="/verifyEmail/:token">
        <VerifyEmail />
      </Route>

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
