import React from "react";
import "./successPage.css";

function Success() {
  return (
    <div className="success-container">
      <div className="success-content">
        <div className="icon-container">
          {/* Replace with your icon component or image */}
          <span className="icon" role="img" aria-label="">
            ✔️
          </span>
        </div>
        <h2 className="success-title">Preferences Updated</h2>
        <p className="success-message">
          Your communication preferences have been successfully updated. These
          changes will take effect immediately.
        </p>
      </div>
    </div>
  );
}

export default Success;
