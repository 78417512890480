import React, { useEffect, useState } from "react";
import "./pages/managePage.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Loader from "./Loader";

function ManagePage() {
  const params = new URLSearchParams(window.location.search);
  const queries = Object.fromEntries(params.entries());

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [unsubscribeReason, setUnsubscribeReason] = useState("");
  // Fetch user preferences
  useEffect(() => {
    const fetchData = async () => {
      if (!queries.c_ref) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/preferences/org/lmgt?c_ref=${queries.c_ref}`
        );

        if (response.status === 200 && response?.data?.status) {
          setData(response.data.data);
          setSelectedPreferences(
            response.data.data?.leadPreferences.map(
              item => item.Preference_Center__c
            )
          );
        } else {
          toast.error("Something went wrong, please contact an admin");
        }
      } catch (err) {
        toast.error("Something went wrong, please contact an admin");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [queries.c_ref]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (
      hasInteracted &&
      selectedPreferences.length === 0 &&
      !unsubscribeReason
    ) {
      toast.warn("Please select a reason for unsubscribing");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/preferences/org/lmgt`,
        {
          leadId: data.lead.id,
          selectedPreferences,
          reason: selectedPreferences.length === 0 ? unsubscribeReason : null,
          preferenceCenters: data.preferenceCenters,
          leadPreferences: data.leadPreferences.map(ele => ele.Id)
        }
      );

      if (response.status === 200 && response?.data?.status) {
        window.location.href = "/manage/pref/success";
      } else {
        toast.error("Failed to update preferences");
      }
    } catch (err) {
      toast.error("Failed to update preferences");
    } finally {
      setLoading(false);
    }
  };

  const handlePreferenceChange = id => {
    setHasInteracted(true);
    setSelectedPreferences(prev =>
      prev.includes(id) ? prev.filter(prefId => prefId !== id) : [...prev, id]
    );
  };

  if (loading && !data) return <Loader />;
  if (!data) return null;

  const showUnsubscribeReason =
    hasInteracted && selectedPreferences.length === 0;

  return (
    <div className="preference-container">
      <div className="preference-content">
        <h1>Update Your Email Preferences</h1>
        <div className="email-info">
          <span className="font-weight-bolder">
            {/* <b> */}
            {data.lead?.name} - ({data.lead?.email}){/* </b> */}
          </span>
        </div>

        <form onSubmit={handleSubmit} className="preference-form">
          <div className="preferences-list">
            {data.preferenceCenters?.map(preference => (
              <label key={preference.Id} className="preference-item">
                <input
                  type="checkbox"
                  checked={selectedPreferences.includes(preference.Id)}
                  onChange={() => handlePreferenceChange(preference.Id)}
                  className="preference-checkbox"
                />
                <span>{preference.Name}</span>
              </label>
            ))}
          </div>

          {showUnsubscribeReason && (
            <div className="unsubscribe-reason">
              <label>Why are you unsubscribing?</label>
              <select
                value={unsubscribeReason}
                onChange={e => setUnsubscribeReason(e.target.value)}
                className="reason-select"
              >
                <option value="">Select a reason</option>
                <option value="No longer interested">
                  No longer interested
                </option>
                <option value="Content not relevant">
                  Content not relevant
                </option>
                <option value="Too many emails">Too many emails</option>
                <option value="Never subscribed">I never subscribed</option>
                <option value="Other">Other</option>
              </select>
            </div>
          )}

          <button
            type="submit"
            disabled={loading || !hasInteracted}
            className="update-button"
          >
            {loading ? (
              <span className="button-content">
                <div className="spinner" />
                Updating...
              </span>
            ) : (
              "Update Preferences"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ManagePage;
